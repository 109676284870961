import './ConfKeywordsExcluded.css';
import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import { Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Typeahead } from 'react-bootstrap-typeahead';
import * as Consts from '../constants/constants';
import {getCookie, setCookie} from '../services/common'; 

import 'react-bootstrap-typeahead/css/Typeahead.css';

export class ConfKeywordsExcluded extends Component {
  constructor(props) {
    super(props);
    this.state = {clientProfiles: [], profileId: null, clientKeywords: [], toShowDeleteDialog: false, toDeleteId: null, allKeywords: [], keyword2Add: null, isAdding: false, linkedKeywords: []};
  }

  componentWillMount() {
  }

  componentDidMount() {
    document.title = 'Keywords Excluded';
    this.setState({profileId: getCookie('sparkai2_profile_id')});
    this.loadMyProfiles();
    this.loadAllKeywords();
  }

  componentWillUnmount() {
  }

  loadMyProfiles() {
    fetch(Consts.API_MY_PROFILES, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_MY_PROFILES");
      console.log(data);
      this.setState({
        clientProfiles: data
      });
      if ((this.state.profileId == null || this.state.profileId === "") && data.length > 0) {
        setCookie('sparkai2_profile_id', data[0].id, 30);
        this.setState({
          profileId: data[0].id
        });
      }
      this.loadMyKeywords();
    })
    .catch(console.log) 
  }

  loadMyKeywords() {
    if (this.state.profileId == null || this.state.profileId === "") return;
    fetch(Consts.API_MY_KEYWORDS_EXCLUDED + "?profileId=" + this.state.profileId, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_MY_KEYWORDS_EXCLUDED");
      console.log(data);
      this.setState({
        clientKeywords: data
      });
    })
    .catch(console.log) 
  }

  loadAllKeywords() {
    fetch(Consts.API_ALL_KEYWORDS, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_ALL_KEYWORDS");
      console.log(data);
      this.setState({
        allKeywords: data
      });
    })
    .catch(console.log) 
  }

  loadLinkedKeywords(keywordId) {
    fetch(Consts.API_LINKED_KEYWORDS + "?keywordId=" + keywordId, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_LINKED_KEYWORDS");
      console.log(data);
      this.setState({
        linkedKeywords: data
      });
    })
    .catch(console.log) 
  }

  clientDeleteKeyword(keywordId) {
    const that = this;

    fetch(Consts.API_MY_KEYWORD_DELETE + "?keywordId=" + keywordId, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      },
    }).then(function(response) {
      if (response.status === 400) {
        console.log(response);
        return response.json();
      } else {
        window.location.reload();
        throw new Error('handled');
      }
    }).then(function(object) {
      window.alert("400\r\n" + object.message);
      that.setState({toShowDeleteDialog: false});
    }).catch(function(error){
      console.log("error");
      console.log(error);
      if (error === 'handled') {
        return;
      } else {
        console.log(error);
      }
      that.setState({toShowDeleteDialog: false});
    });
  }

  clientAddKeyword(keyword) {
    console.log("clientAddKeyword, keyword=" + keyword);
    var keywordId = -1;
    for (var i = 0; i < this.state.allKeywords.length; i++) {
      if (this.state.allKeywords[i].keyword === keyword) {
        keywordId = this.state.allKeywords[i].id;
      }
    }
    console.log("clientAddKeyword, keywordId=" + keywordId);
    let data = {"profileId": this.state.profileId, "keywordId": keywordId, "order": this.state.clientKeywords.length, "toInclude": false};
    console.log("clientAddKeyword, data=" + JSON.stringify(data));

    this.setState({isAdding: true});
    const that = this;

    fetch(Consts.API_MY_KEYWORD_ADD, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      },
    }).then(function(response) {
      if (response.status === 400) {
        console.log(response);
        return response.json();
      } else {
        window.location.reload();
        throw new Error('handled');
      }
    }).then(function(object) {
      that.setState({isAdding: false});
    }).catch(function(error){
      console.log("error");
      console.log(error);
      if (error === 'handled') {
        return;
      } else {
        console.log(error);
      }
    });
  }

  handleChangeProfile(event) {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    console.log(fieldName);
    console.log(fieldVal);
    setCookie('sparkai2_profile_id', fieldVal, 30);
    this.setState({profileId: fieldVal});
    window.location.reload(); 
  }

  render() {
    var showDeleteDialog = '';
    if (this.state.toShowDeleteDialog) {
      showDeleteDialog = <>
        <Modal show={this.state.toShowDeleteDialog} onHide={() => this.setState({toShowDeleteDialog: false})} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Delete keyword?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({toShowDeleteDialog: false})}>Close</Button>
            <Button variant="danger" onClick={() => this.clientDeleteKeyword(this.state.toDeleteId)}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </>
    }

    var addButton = <Button variant="primary" onClick={() => this.clientAddKeyword(this.state.keyword2Add)}>
      Add
    </Button>;
    if (this.state.isAdding) {
      addButton = <Button variant="primary">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Add
      </Button>;
    }

    return <div className="outer100">
      <div className="aboutus-center">
        <Form.Group>
          <Form.Label>Profile</Form.Label>
          <Form.Control controlId="profileControl" as="select" readOnly onChange={this.handleChangeProfile.bind(this)} defaultValue={this.state.profileId} value={this.state.profileId}>
            {
              this.state.clientProfiles.map((option, index) => {
                return (<option key={option.id} value={option.id}>{option.title}</option>)
              })
            }
          </Form.Control>
        </Form.Group>
        <br/>

        <table>
          <tr>
            <td>
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>keyword</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.clientKeywords.map((row, i) => {
                      return (
                        <tr key={"clientKeywordsTr_" + i} onClick={() => this.loadLinkedKeywords(row.id)}>
                          <td>{row.id}</td>
                          <td>{row.keyword}</td>
                          <td>
                            <nobr>
                              <img className="img-page" src='/img/delete-icon-24.png' onClick={() => this.setState({toShowDeleteDialog: true, toDeleteId: row.id})} /> 
                            </nobr>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>  
              </div>
            </td>
            <td style={{ textAlign: "center", width: 400}}>
              <Typeahead
                id="conf-keyword-all-selector"
                onChange={(selected) => {
                  console.log(selected[0]);
                  this.setState({keyword2Add: selected[0]});
                }}
                options={this.state.allKeywords.map((k) => k.keyword)}
              />
              {addButton}
            </td>
            <td>
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>linked keyword</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.linkedKeywords.map((row, i) => {
                      return (
                        <tr key={"linkedKeywordsTr_" + i}>
                          <td>{row.id}</td>
                          <td>{row.keyword}</td>
                          <td>
                            <nobr>
                              <img className="img-page" src='/img/math-add-icon-24.png' onClick={() => {this.setState({isAdding: true}); this.clientAddKeyword(row.keyword)}} /> 
                            </nobr>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>  
              </div>
            </td>
          </tr>
        </table>

        {showDeleteDialog}

      </div>
    </div>
  }
}

export default ConfKeywordsExcluded