import './Signup.css';
import React, { Component } from 'react'
import { Button, Form/*, Navbar, Nav, NavDropdown, FormControl*/ } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert'; 
// import PropTypes from 'prop-types'
import * as Consts from '../constants/constants';
import { withRouter } from 'react-router-dom';
import { setCookie } from '../services/common';
import md5 from 'js-md5'

export class Signup extends Component {
  static propTypes = {}

  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {isSaving: false};
  }

  componentDidMount() {
    document.title = 'Signup';
  }

  handleSubmit(event) {
    event.preventDefault();
    // let data = new FormData();
    let data = {};
    // let data = new FormData(event.target);
    const data2 = event.target;
    // console.log("data.get('email')=" + data.get('email'));
    // data.set('email', data2.elements.email.value);
    // data.set('password', data2.elements.password.value);
    data.login = data2.elements.login.value;
    data.password = md5(data2.elements.password1.value + "klgKd74MBtVgoAyZyS");
    // console.log("data");
    // console.log(data);
    if (data2.elements.password1.value != data2.elements.password2.value) {
      this.setState({toastMessage: "Passwords don't match"});
      setTimeout(() => this.setState({toastMessage: null}), 4000);       
      return;
    }
    // console.log(event.target);
    // console.log(data.keys());
    // console.log("data.get('email')=" + data.get('email'));
    // console.log(data2.elements.email.value);

    this.setState({isSaving: true});
    const that = this;

    fetch(Consts.API_SIGNUP, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.json())
    .then((data2) => {
      console.log(data2);

      // const keys = headers.keys();
      // let header = keys.next();
      // while (header.value) {
      //   console.log(header);
      //   console.log(header.value);
      //   header = keys.next();
      // }
      // for (var i = 0; i < headers.size(); i++) {
      //   var header2 = headers.get(i);
      //   console.log(header2);
      //   console.log(header2.name + " =, " + header2.value);
      // }
      if (data2.result == "ok") {
        document.cookie = "sparkai2_login=" + data.login;
        document.cookie = "sparkai2_passwordMd5=" + data.password;
        this.props.history.push({pathname: '/trainingStart'});
        window.location.reload();
      } else {
        that.setState({isSaving: false, toastMessage: "Bad Username or Password"});
        setTimeout(() => that.setState({toastMessage: null}), 4000);       
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  render() {
    var signupButton = <Button variant="primary" type="submit">
      Signup
    </Button>;
    if (this.state.isSaving) {
      signupButton = <Button variant="primary">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Signup
      </Button>;
    }

    var alertToast = "";
    if (this.state.toastMessage) {
      alertToast = <Alert variant="warning">
        {this.state.toastMessage}
      </Alert>
    }
 
    return <div className="outer100">
      <div className="signup-center">
        <Alert key="alert-gdpr" variant="primary">
          By signing up you agree to storing Cookies
        </Alert>

        {alertToast}

        <br/>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Login</Form.Label>
            <Form.Control type="text" placeholder="Enter login" name="login"/>
            <Form.Text className="text-muted">
              Will be used for restoring password
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword1">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" name="password1"/>
            <Form.Text className="text-muted">
              Minimum 6 characters, maximum 40 characters
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword2">
            <Form.Label>Repeat password</Form.Label>
            <Form.Control type="password" name="password2"/>
          </Form.Group>

          {signupButton}
        </Form>
      </div>
      <br/><br/><br/><br/><br/>
      <iframe className="youtube" src="https://www.youtube.com/embed/K0ieibSZZuo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  }
}

export default withRouter (Signup)