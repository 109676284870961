import './ConfProfiles.css';
import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import { Form, Button } from 'react-bootstrap'; 
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Typeahead } from 'react-bootstrap-typeahead';
import * as Consts from '../constants/constants';
import { getCookie } from '../services/common';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export class ConfProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {clientProfiles: [], toShowDeleteDialog: false, toDeleteId: null, isAdding: false};
    this.handleAdd = this.handleAdd.bind(this); 
  }

  componentWillMount() {
  }

  componentDidMount() {
    document.title = 'Profiles';
    this.loadMyProfiles();
  }

  componentWillUnmount() {
  }

  loadMyProfiles() {
    fetch(Consts.API_MY_PROFILES, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_MY_PROFILES");
      console.log(data);
      this.setState({
        clientProfiles: data
      });
    })
    .catch(console.log) 
  }

  clientDeleteProfile(profileId) {
    const that = this;

    fetch(Consts.API_MY_PROFILE_DELETE + "?profileId=" + profileId, {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      },
    }).then(function(response) {
      if (response.status === 400) {
        console.log(response);
        return response.json();
      } else {
        window.location.reload();
        throw new Error('handled');
      }
    }).then(function(object) {
      window.alert("400\r\n" + object.message);
      that.setState({toShowDeleteDialog: false});
    }).catch(function(error){
      console.log("error");
      console.log(error);
      if (error === 'handled') {
        return;
      } else {
        console.log(error);
      }
      that.setState({toShowDeleteDialog: false});
    });
  }

  handleAdd(event) {
    event.preventDefault();
    let data = {};
    const data2 = event.target;

    data.title = data2.elements.titleNew.value;

    this.setState({isAdding: true});

    fetch(Consts.API_MY_PROFILE_ADD, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      },
    }).then(response => response.headers)
    .then(headers => {
      this.setState({isAdding: false});
      window.location.reload();
    })
    .catch(error => {
      this.setState({isAdding: false});
      console.error('Error:', error);
    });
  }
 
  render() {
    var showDeleteDialog = '';
    if (this.state.toShowDeleteDialog) {
      showDeleteDialog = <>
        <Modal show={this.state.toShowDeleteDialog} onHide={() => this.setState({toShowDeleteDialog: false})} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Delete profile?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({toShowDeleteDialog: false})}>Close</Button>
            <Button variant="danger" onClick={() => this.clientDeleteProfile(this.state.toDeleteId)}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </>
    }

    var addButton = <Button variant="primary" type="submit">
      Add
    </Button>;
    if (this.state.isAdding) {
      addButton = <Button variant="primary" type="submit">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Add
      </Button>;
    }

    return <div className="outer100">
      <div className="profiles-center">
        <br/>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th width="80px">#</th>
              <th>title</th>
              <th width="80px"></th>
            </tr>
          </thead>
          <tbody>
            {this.state.clientProfiles.map((row, i) => {
              return (
                <tr key={"clientProfilesTr_" + i}>
                  <td>{row.id}</td>
                  <td>{row.title}</td>
                  <td>
                    <nobr>
                      <img className="img-page" src='/img/delete-icon-24.png' onClick={() => this.setState({toShowDeleteDialog: true, toDeleteId: row.id})} /> 
                    </nobr>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>  

        <br/><br/>
        <Form onSubmit={this.handleAdd}>
          <Form.Group controlId="formBasicName">
            <Form.Label>Title</Form.Label>
            <Form.Control placeholder="Enter title" name="titleNew"/>
          </Form.Group>

          <div className="text-align-center">
            {addButton}
          </div>
        </Form> 

        {showDeleteDialog}

      </div>
    </div>
  }
}

export default ConfProfiles