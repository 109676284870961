import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route/*, Link*/ } from "react-router-dom";
import Signin from './signin/Signin';
import Signup from './signup/Signup'; 
import TrainingStart from './trainingStart/TrainingStart';
import Training from './training/Training';
import ConfProfiles from './confProfiles/ConfProfiles';
import ConfKeywordsIncluded from './confKeywordsIncluded/ConfKeywordsIncluded';
import ConfKeywordsExcluded from './confKeywordsExcluded/ConfKeywordsExcluded';
import {isLogged, getCookie, setCookie, deleteCookie, commonLogout, findGetParameter} from './services/common';
import * as Consts from './constants/constants';
import { v4 as uuidv4 } from 'uuid';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export class App extends Component {
  static propTypes = {}

  constructor() {
    super();
    console.log("window.location.search=" + window.location.search);
    console.log("window.location.pathname=" + window.location.pathname);
  }

  componentWillMount() {
    console.log("componentWillMount");
    if (!isLogged() && !(window.location.pathname === "/signin" || window.location.pathname === "/signup")) {
      console.log("create temporal account");
      document.cookie = "sparkai2_login2=" + uuidv4();
    }
  }

  componentDidMount() {
  }

  logout(event) {
    console.log("logout");
    commonLogout();
    window.location = window.location.protocol + '//' + window.location.host + '/signin';
    // window.location.reload();
    // this.props.history.push({pathname: '/signin', state: {profileId: this.state.profileId}});
    // window.location.reload();
  }
 
  render() {
    console.log("render");
    let buttonSigninLogout;
    if (isLogged()) {
      var name = getCookie("sparkai2_login");
      if (name === "" || name === null) {
        name = "Temporal Account";
      }
      var welcomeStr = "Hello, " + name;
      buttonSigninLogout = <Nav>
        <Nav.Link disabled>{welcomeStr}</Nav.Link>
        <Nav.Link onClick={()=> this.logout()}>Logout</Nav.Link>
      </Nav>;
    } else {
      buttonSigninLogout = <Nav>
        <Nav.Link href="/signin">Signin</Nav.Link>
        <Nav.Link href="/signup">Signup</Nav.Link>
      </Nav>;
    } 

    let usefulLinks = <Nav className="mr-auto">
      <Nav.Link href="/trainingStart">Training</Nav.Link>
      <NavDropdown title="Configuration" id="basic-nav-dropdown">
        <NavDropdown.Item href="/confProfiles">Profiles</NavDropdown.Item>
        <NavDropdown.Item href="/confKeywordsIncluded">Keywords Included</NavDropdown.Item>
        <NavDropdown.Item href="/confKeywordsExcluded">Keywords Excluded</NavDropdown.Item>
      </NavDropdown>
    </Nav>
    if (!isLogged()) {
      usefulLinks = <Nav className="mr-auto"></Nav>
    }

    let defaultRoute = <Route path="/">
      <TrainingStart />
    </Route>
    if (!isLogged()) {
      defaultRoute = <Route path="/">
        <Signin />
      </Route>
    }

    return (
      <Router>
        <div className="App">
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">Speak AI</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {usefulLinks}
              {buttonSigninLogout}              
            </Navbar.Collapse>
          </Navbar>    
        </div>
        <Switch>
          <Route path="/signin">
            <Signin />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/trainingStart">
            <TrainingStart />
          </Route>
          <Route path="/training">
            <Training />
          </Route>
          <Route path="/confProfiles">
            <ConfProfiles />
          </Route>
          <Route path="/confKeywordsIncluded">
            <ConfKeywordsIncluded />
          </Route>
          <Route path="/confKeywordsExcluded">
            <ConfKeywordsExcluded />
          </Route>
          {defaultRoute}
        </Switch>
      </Router>
    );
  }

}

export default App;
