export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
} 

export const isLogged = () => {
  console.log("isLogged=" + ((getCookie("sparkai2_login") != null && getCookie("sparkai2_login") != "")  || (getCookie("sparkai2_login2") != null && getCookie("sparkai2_login2") != "")));
  return (getCookie("sparkai2_login") != null && getCookie("sparkai2_login") != "")  || (getCookie("sparkai2_login2") != null && getCookie("sparkai2_login2") != "");
}

export const commonLogout = () => {
  deleteCookie("sparkai2_profile_id");
  deleteCookie("sparkai2_login2");
  return deleteCookie("sparkai2_login");
}

export const deleteCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const findGetParameter = (parameterName) => {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export const getUrlParam = (name) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let nameValue = params.get(name);
  return nameValue;
}

