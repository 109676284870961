import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

export const withRouterForwardRef = Wrapped => {
  const WithRouter = withRouter(({ forwardedRef, ...props }) => (
    <Wrapped ref={forwardedRef} {...props} />
  ));

  return React.forwardRef((props, ref) => (
    <WithRouter {...props} forwardedRef={ref} />
  ));
}

// export const withRouterForwardRef = (WrappedComponent) => {
//   class InnerComponentWithRef extends React.Component {    
//     render() {
//       const { forwardedRef, ...rest } = this.props;
//       return <WrappedComponent {...rest} ref={forwardedRef} />;
//     }
//   }
//   const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });
//   return React.forwardRef((props, ref) => {
//     return <ComponentWithRouter {...props} forwardedRef={ref} />;
//   });
// }
