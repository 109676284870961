import './TrainingStart.css';
import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap';
import * as Consts from '../constants/constants';
import {getCookie, setCookie} from '../services/common'; 
import {withRouterForwardRef} from '../withRouterForwardRef/withRouterForwardRef';

export class TrainingStart extends Component {
  constructor(props) {
    super(props);
    this.state = {clientProfiles: [], profileId: null};
  }

  componentWillMount() {
  }

  componentDidMount() {
    document.title = 'Start Training';
    this.setState({profileId: getCookie('sparkai2_profile_id')});
    this.loadMyProfiles();
  }

  componentWillUnmount() {
  }

  loadMyProfiles() {
    fetch(Consts.API_MY_PROFILES, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log("API_MY_PROFILES");
      console.log(data);
      this.setState({
        clientProfiles: data
      });
      if ((this.state.profileId == null || this.state.profileId === "") && data.length > 0) {
        setCookie('sparkai2_profile_id', data[0].id, 30);
        this.setState({
          profileId: data[0].id
        });
      }
    })
    .catch(console.log) 
  }

  handleChangeProfile(event) {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    console.log(fieldName);
    console.log(fieldVal);
    setCookie('sparkai2_profile_id', fieldVal, 30);
    this.setState({profileId: fieldVal});
    window.location.reload(); 
  }

  startTraining() {
    this.props.history.push({pathname: '/training', state: {profileId: this.state.profileId}});
    window.location.reload();
  }

  render() {
    return <div className="outer100">
      <div className="training-start-center text-align-center">
        <br/>
        <Form.Group bsPrefix="form-group">
          <Form.Label>Profile</Form.Label>
          <Form.Control bsPrefix="form-control" controlId="profileControl" as="select" readOnly onChange={this.handleChangeProfile.bind(this)} defaultValue={this.state.profileId} value={this.state.profileId}>
            {
              this.state.clientProfiles.map((option, index) => {
                return (<option key={option.id} value={option.id}>{option.title}</option>)
              })
            }
          </Form.Control>
        </Form.Group>
        <br/>

        <Button variant="primary" onClick={() => this.startTraining()}>
          Start training
        </Button>

        <br/><br/><br/><br/><br/>
        <iframe className="youtube" src="https://www.youtube.com/embed/K0ieibSZZuo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  }
}

export default withRouterForwardRef (TrainingStart)

