// export const WEB_URL = "http://localhost:3000"
export const WEB_URL = "https://speakai2.com"
// export const API_DOMAIN = "http://localhost:8011"
export const API_DOMAIN = "https://api.speakai2.com"
export const API_ALL_KEYWORDS = API_DOMAIN + '/keyword/list';

export const API_MY_KEYWORDS_INCLUDED = API_DOMAIN + '/client/keyword/list-included';
export const API_MY_KEYWORDS_EXCLUDED = API_DOMAIN + '/client/keyword/list-excluded';
export const API_LINKED_KEYWORDS = API_DOMAIN + '/client/keyword/linked-list';
export const API_MY_KEYWORD_DELETE = API_DOMAIN + '/client/keyword/delete';
export const API_MY_KEYWORD_ADD = API_DOMAIN + '/client/keyword/put';

export const API_MY_PROFILES = API_DOMAIN + '/client/profile/list';
export const API_MY_PROFILE_ADD = API_DOMAIN + '/client/profile/add';
export const API_MY_PROFILE_DELETE = API_DOMAIN + '/client/profile/delete';

export const API_TRAINING_PROPOSAL = API_DOMAIN + '/training/proposal';
export const API_TRAINED_ARTICLE = API_DOMAIN + '/training/trained';

export const API_SIGNIN = API_DOMAIN + '/auth/signin';
export const API_SIGNUP = API_DOMAIN + '/auth/signup';

