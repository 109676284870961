import './Training.css';
import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import { Button, Card, Accordion, Overlay, OverlayTrigger, ProgressBar } from 'react-bootstrap';
import Tooltip from "react-bootstrap/Tooltip";
import * as Consts from '../constants/constants';
import {getCookie, setCookie} from '../services/common'; 
import { withRouter } from 'react-router-dom'; 

export class Training extends Component {
  constructor(props) {
    super(props);
    let exProfileId = null; 
    if (this.props.location != undefined && this.props.location.state != undefined && this.props.location.state.profileId != undefined) {
      exProfileId = this.props.location.state.profileId;
    } 
    this.state = {exProfileId: exProfileId, article: null, statement: null, questions: [], anwerBtnTitles: null, loadingProgress: 0};
  }

  componentWillMount() {
  }

  componentDidMount() {
    document.title = 'Training';
    this.loadProposal();
    const that = this;
    setInterval(function() {
      that.setState({loadingProgress: that.state.loadingProgress + 1})
    }, 1000);
  }

  componentWillUnmount() {
  }

  loadProposal() {
    let thisObj = this; 
    fetch(Consts.API_TRAINING_PROPOSAL + "?profileId=" + this.state.exProfileId, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    }).then(function(response) {
      if (response.status === 403) {
        window.alert("Training limit (5 requests per day) is exceeded");
        throw new Error('403');
      } else {
        return response;
      }
    }).then(res => res.json())
    .then((data) => {
      console.log("API_TRAINING_PROPOSAL");
      console.log(data);
      let article = data.article;
      let statement = data.questions.statement;
      let questions = data.questions.questions;
      var anwerBtnTitles = {};
      questions.map((q, i) => {anwerBtnTitles["id" + q.id] = q.answer});
      console.log("anwerBtnTitles");
      console.log(anwerBtnTitles);
      this.setState({
        article: article, statement: statement, questions: questions, anwerBtnTitles: anwerBtnTitles
      });
    }).catch(function(error){
      console.log("error");
      console.log(error);
      thisObj.props.history.goBack(); 
    }); 
  }

  trained(articleId) {
    let thisObj = this;
    fetch(Consts.API_TRAINED_ARTICLE + "?articleId=" + articleId, {
      "method": "GET",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "login2": getCookie("sparkai2_login2"),
        "login": getCookie("sparkai2_login"),
        "passwordMd5": getCookie("sparkai2_passwordMd5"),
      }
    }).then(function(response) {
      if (response.status === 400) {
        console.log(response);
        return response.json();
      } else {
        thisObj.props.history.goBack();
        throw new Error('handled');
      }
    }).then(function(object) {
    }).catch(function(error){
      console.log("error");
      console.log(error);
      if (error === 'handled') {
        return;
      } else {
        console.log(error);
      }
    }); 
  }

  say(url) {
    var myAudio = new Audio(url);
    myAudio.play();
  }

  showAnswer(id, answer) {
  }

  render() {
    let title = this.state.article ? this.state.article.title : "";
    let desc = this.state.article ? this.state.article.desc : "";

    let questionsDiv = <div></div>
    let trainingBlock = <div></div>
    if (this.state.article) {
      questionsDiv = <Accordion>
        {this.state.questions.map((q, i) => {
          return (
            <Card>
              <Card.Header>
                <table>
                  <tr>
                    <td>
                      <Accordion.Toggle as={Button} variant="link" eventKey={q.id} className="training-btn">
                        Ques&#8203;tion {q.id}
                      </Accordion.Toggle>
                    </td>
                    <td>
                      <table>
                        <tr>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            Normal
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            Slower
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            Slowest
                          </td>
                        </tr>
                        <tr>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            <img className="img-page" src='/img/Bullhorn-icon-32.png' onClick={() => this.say(q.questionMp3Url + "_m.mp3")} />
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            <img className="img-page" src='/img/Bullhorn-icon-32.png' onClick={() => this.say(q.questionMp3Url + "_s.mp3")} />
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;
                            <img className="img-page" src='/img/Bullhorn-icon-32.png' onClick={() => this.say(q.questionMp3Url + "_xs.mp3")} />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </Card.Header>
              <Accordion.Collapse eventKey={q.id}>
                <div>
                  <Card.Body>{q.question}</Card.Body>
                  <OverlayTrigger
                    trigger="click" 
                    placement="right"
                    overlay={<Tooltip id="button-tooltip">{q.answer}</Tooltip>}
                  >
                    <Button variant="success">Answer</Button>
                  </OverlayTrigger>  
                </div>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>

      trainingBlock = <div>
        <Jumbotron fluid>
          <Container>
            <h3>Context</h3>
            <br/>
            <p style={{fontSize: 20}}>{title}</p>
            <p style={{fontSize: 17}}>{desc}</p>
          </Container>
        </Jumbotron>

        {questionsDiv}

        <br/><br/>
        <div className="text-align-left">
          <Button variant="success" onClick={() => this.trained(this.state.article.articleId)}>Trained</Button>
        </div>
      </div>
    }

    let loadingBlock = <div></div>
    if (this.state.article === null) {
      loadingBlock = <div className="text-align-center">
        <ProgressBar now={this.state.loadingProgress} label={this.state.loadingProgress} max="15"/>
        <br/>
        It will be immediate when we find investor
      </div>
    }

    return <div className="outer100">
      <div className="training-center">
        <br/>
        {loadingBlock}
        {trainingBlock}
      </div>
    </div>
  }
}

export default withRouter (Training)